import Phaser from 'phaser';

import Popup from './Popup';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';

const { width, height } = configs;

class PopupConfirmSwapXToken extends Popup {
  constructor(scene, parentModal, { onConfirm }) {
    super(scene, 'popup-small');

    const leftMargin = this.popup.x - this.popup.width / 2;
    const checkBoxX = leftMargin + this.popup.width * 0.15;
    const startingY = this.popup.y - this.popup.height / 2;
    const imgY = startingY + 150;
    const mainTextY = imgY + 470;
    const checkBoxY = mainTextY + 200;

    const img = scene.add.image(width / 2, imgY, `swap-xgang-gang`).setOrigin(0.5, 0);
    this.add(img);
    const bg = scene.add.image(width / 2, mainTextY, 'text-container');
    this.add(bg);

    this.description = scene.add
      .text(width / 2, mainTextY, 'Are you sure you want to swap?', {
        fontSize: fontSizes.large,
        color: colors.black,
        fontFamily: fontFamilies.bold,
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.description);

    const buttonBack = new TextButton(
      scene,
      width / 2 - this.popup.width * 0.23,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
        parentModal.open();
      },
      'Back',
      { fontSize: '82px', sound: 'close' }
    );
    this.buttonApprove = new TextButton(
      scene,
      width / 2 + this.popup.width * 0.23,
      height / 2 + this.popup.height / 2 - 20,
      'button-green',
      'button-green-pressed',
      () => {
        onConfirm();
        this.close();
      },
      'Approve',
      { sound: 'button-1', fontSize: '82px', disabledImage: 'button-disabled' }
    );
    this.buttonApprove.setDisabledState(true);
    this.add(buttonBack);
    this.add(this.buttonApprove);

    this.checkboxUnchecked = scene.add.image(checkBoxX, checkBoxY, 'icon-checkbox-false').setOrigin(0.5, 0);
    this.checkboxChecked = scene.add
      .image(checkBoxX, checkBoxY, 'icon-checkbox-true')
      .setOrigin(0.5, 0)
      .setVisible(false);
    this.add(this.checkboxUnchecked);
    this.add(this.checkboxChecked);

    this.checkboxUnchecked.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
      const approvedState = !this.checkboxChecked.visible;
      this.checkboxChecked.setVisible(approvedState);
      this.buttonApprove.setDisabledState(!approvedState);
    });

    this.checkboxText = scene.add.text(
      checkBoxX + 50,
      checkBoxY,
      `I understand that there's a cooldown\nto swap again.`,
      {
        fontSize: fontSizes.medium,
        color: colors.black,
        fontFamily: fontFamilies.bold,
      }
    );
    this.add(this.checkboxText);

    scene.game.events.on('update-last-swap-x-token', ({ swapXTokenGapInSeconds }) => {
      if (swapXTokenGapInSeconds) {
        const cooldownInHours = swapXTokenGapInSeconds / 3600;
        this.checkboxText.text = `I understand that I won't be able\nto swap again for ${cooldownInHours.toLocaleString()} hours`;
      }
    });
  }

  cleanup() {
    if (this.checkboxChecked) {
      const approvedState = false;
      this.checkboxChecked.setVisible(approvedState);
      this.buttonApprove.setDisabledState(!approvedState);
    }
  }

  updateDescription(text) {
    this.description.text = text;
  }
}

export default PopupConfirmSwapXToken;
