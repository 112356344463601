import Phaser from 'phaser';

import Button from '../button/Button';
import configs from '../../configs/configs';
import { fontFamilies } from '../../../../utils/styles';

class PopupSpinPlaytestReward extends Phaser.GameObjects.Container {
  iconY = configs.height / 2 + 50;
  iconX = configs.width / 2;
  reward = null;
  template = null;

  constructor(scene) {
    super(scene, 0, 0);
    this.setVisible(false);

    this.sound = scene.sound.add('spin-result-sound', { loop: false });

    this.background = scene.add.rectangle(0, 0, configs.width, configs.height, 0x000000, 0.4).setOrigin(0, 0);
    this.add(this.background);

    this.glowFx = scene.add.image(this.iconX, this.iconY, 'spin-reward-glow-fx').setOrigin(0.5, 0.5);
    this.add(this.glowFx);

    this.icon = scene.add.sprite(this.iconX, this.iconY, '').setOrigin(0.5, 0.5);
    this.add(this.icon);

    this.wonText = scene.add
      .text(this.iconX, this.iconY, 'YOU HAVE WON', {
        fontSize: '64px',
        fontFamily: fontFamilies.extraBold,
        color: '#fff',
      })
      .setOrigin(0.5, 0.5);
    this.wonText.setStroke('#591C9A', 10);
    this.add(this.wonText);

    this.amountText = scene.add
      .text(this.iconX, this.iconY, '0', { fontSize: '116px', fontFamily: fontFamilies.extraBold, color: '#FFDD1D' })
      .setOrigin(0.5, 0.5);
    this.amountText.setStroke('#591C9A', 15);
    this.add(this.amountText);

    this.rewardText = scene.add
      .text(this.iconX, this.iconY, '0', { fontSize: '116px', fontFamily: fontFamilies.extraBold, color: '#fff' })
      .setOrigin(0.5, 0.5);
    this.rewardText.setStroke('#591C9A', 15);
    this.add(this.rewardText);

    this.claimBtn = new Button(
      scene,
      this.iconX,
      this.iconY,
      'button-claim-ueth',
      'button-claim-ueth-pressed',
      () => {
        scene.game.events.emit('claim-ueth', { isDouble: false });
        this.close();
        scene.popupUETHClaimed.showReward(this.reward);
      },
      { sound: 'close' }
    );
    this.add(this.claimBtn);

    this.shareToDoubleBtn = new Button(
      scene,
      this.iconX,
      this.iconY,
      'button-share-ueth',
      'button-share-ueth-pressed',
      () => {
        scene.game.events.emit('claim-ueth', { isDouble: true });
        this.close();
        scene.popupUETHClaimed.showReward({ ...this.reward, value: this.reward?.value * 2 });
        const text = this.template;
        const intentUrl = getTwitterIntentUrl({ text });
        window.open(intentUrl);
      },
      { sound: 'close' }
    );
    this.add(this.shareToDoubleBtn);

    this.scene = scene;

    scene.game.events.on('update-twitter-share-ueth-template', ({ template }) => {
      this.template = template;
    });

    scene.game.events.emit('request-twitter-share-ueth-template');
  }

  /* effects */
  // override to run effects after opening popup
  onOpen() {}
  // override to run effects before closing popup
  cleanup() {}

  open() {
    window.poof?.();
    this.setVisible(true);
    this.sound.play();
  }

  close = () => {
    this.setVisible(false);
    this.sound.stop();
    this.amountText.x = this.iconX;
  };

  showReward(reward) {
    const { iconImg, value } = reward;
    this.reward = reward;
    const rewardText = `uETH`;

    this.icon.setTexture(iconImg);
    if (this.icon.width > configs.width) {
      this.icon.displayWidth = configs.width * 0.8;
      this.icon.scaleY = this.icon.scaleX;
    }

    this.amountText.text = `${value}`;
    this.rewardText.text = rewardText;

    this.wonText.y = this.iconY + this.icon.height / 2 + 80;
    this.amountText.y = this.wonText.y + 100;
    this.rewardText.y = this.amountText.y;
    this.claimBtn.y = this.rewardText.y + 300;
    this.shareToDoubleBtn.y = this.claimBtn.y + 250;

    this.amountText.x -= this.rewardText.width / 2 + 10;
    this.rewardText.x = this.amountText.x + this.amountText.width / 2 + this.rewardText.width / 2 + 20;

    this.open();
  }
}

export default PopupSpinPlaytestReward;

const getTwitterIntentUrl = ({ text }) => {
  const intentUrl = new URL('https://twitter.com/intent/tweet');

  const encodedText = text.replace(/(\\n)/g, '\n'); // parse stringified \n into newline character
  intentUrl.searchParams.append('text', encodedText);

  return intentUrl;
};
