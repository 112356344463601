import Phaser from 'phaser';

import configs from '../../configs/configs';
import TutorialCharacter from './TutorialCharacter';

const { width, height } = configs;

class StepPlaytest extends Phaser.GameObjects.Container {
  constructor(scene, onNext) {
    super(scene, 0, 0);

    this.setVisible(false);

    this.onNext = onNext;

    this.character = new TutorialCharacter(scene, width / 2, height / 2, 'tutorial-playtest');
    this.add(this.character);
  }

  start() {
    this.scene.tutorial.background.removeAllListeners();
    this.scene.tutorial.background.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
      this.onNext();
    });
    this.setVisible(true);
  }
}

export default StepPlaytest;
