import { useEffect, useMemo, useState } from 'react';
import { useWallets, usePrivy, useConnectWallet } from '@privy-io/react-auth';
import { Web3Provider } from '@ethersproject/providers';
import * as Sentry from '@sentry/react';

import environments from '../utils/environments';
import useEthereumProvider from './useEthereumProvider';

const { NETWORK_ID } = environments;

const useUserWallet = () => {
  const { browserProvider } = useEthereumProvider();
  const [walletProvider, setWalletProvider] = useState(null);
  const { user } = usePrivy();
  const { wallets } = useWallets();
  // console.log('privy', { wallets, user });
  const userWallet = useMemo(
    () => user?.wallet && wallets.find((wallet) => wallet.address.toLowerCase() === user.wallet.address.toLowerCase()),
    [user?.wallet, wallets]
  );
  const embeddedWallet = useMemo(() => wallets.find((wallet) => wallet.walletClientType === 'privy'), [wallets]);
  const externalWallet = useMemo(
    () =>
      user?.linkedAccounts?.find(
        (wallet) =>
          wallet.address !== embeddedWallet?.address &&
          wallet.walletClientType !== 'privy' &&
          wallet.connectorType === 'injected'
      ),
    [wallets, embeddedWallet]
  );

  const switchChain = async () => {
    try {
      userWallet.chainId !== NETWORK_ID && (await userWallet.switchChain(Number(NETWORK_ID)));
    } catch (err) {
      if (err.message.includes(`Request of type 'wallet_switchEthereumChain' already pending`)) return;
      throw err;
    }
  };

  useEffect(() => {
    if (userWallet) {
      switchChain().catch((err) => {
        console.error(err);
        Sentry.captureException(err);
      });
    }
  }, [userWallet]);

  const getDefaultProvider = () => {
    const { ethereum } = window;
    let provider = ethereum;
    if (ethereum?.providers?.length) {
      provider = ethereum.providers?.find((item) => item.isMetaMask) || ethereum.providers[0];
    }

    return new Web3Provider(provider);
  };

  const getProvider = async () => {
    console.log({
      embeddedWallet,
      externalWallet,
      wallets,
      browserProvider,
      userWallet,
    });
    if (externalWallet) {
      const external = wallets.find((item) => item.address.toLowerCase() === externalWallet.address.toLowerCase());
      if (external) {
        return await external.getEthersProvider();
      } else {
        return getDefaultProvider();
      }
    } else if (userWallet.walletClientType === 'privy') {
      return (await userWallet.getEthereumProvider()).provider;
    } else {
      return await userWallet.getEthersProvider();
    }
  };

  return { embeddedWallet, userWallet, externalWallet, getProvider };
};

export default useUserWallet;
