import Phaser from 'phaser';
import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';

import Popup from './Popup';
import PopupTxnError from './PopupTxnError';
import configs from '../../configs/configs';
import { fontFamilies } from '../../../../utils/styles';
import { randomNumberInRange } from '../../../../utils/numbers';
import Button from '../button/Button';

const { width, height } = configs;

const SPIN_ITEM_WIDTH = 560;
const SPIN_ITEM_HEIGHT = 656;
const SPIN_CONTAINER_WIDTH = 1195;
const SPIN_CONTAINER_HEIGHT = 900;
const SPIN_ITEM_GAP = 40;
const ALPHA = 0.5;
const SPIN_DURATION = 10000;

class SpinItem extends Phaser.GameObjects.Container {
  constructor(scene, x, y, item) {
    super(scene, 0, 0);

    const { type, value, iconImg, containerImg } = item;
    this.containerImg = containerImg;

    const text = `${value} uETH`;
    this.container = scene.add.sprite(x, y, containerImg).setOrigin(0.5, 0.5);
    this.icon = scene.add.image(x, y - 60, iconImg).setOrigin(0.5, 0.5);
    // this.icon.displayWidth = this.container.width * 0.8;
    // this.icon.scaleY = this.icon.scaleX;
    this.text = scene.add
      .text(x, y + 230, text, { fontSize: 64, fontFamily: fontFamilies.extraBold })
      .setOrigin(0.5, 0.5);
    this.text.setStroke('#9E0A2E', 15);

    this.add(this.container);
    this.add(this.icon);
    this.add(this.text);
  }
}

class PopupSpinPlaytest extends Popup {
  spinRewards = [];
  numberOfRewards = 0;
  minContainerX = 0;
  maxContainerX = 0;
  cPosition = 0;

  constructor(scene) {
    super(scene, 'popup-spin', { title: 'Starting Bonus', noCloseBtn: true, noCloseOnBackgroundClicked: true });

    this.spinSound = scene.sound.add('spin-sound', { loop: false });
    this.spinSound1 = scene.sound.add('spin-sound-1', { loop: false });

    scene.game.events.on('update-spin-airdrop-rewards', ({ spinRewards }) => {
      if (this.loading) return;
      this.spinRewards = spinRewards;
      this.numberOfRewards = spinRewards.length;

      this.maxContainerX = this.popup.x - this.popup.width / 2 - 1 * SPIN_ITEM_WIDTH - SPIN_ITEM_GAP;
      this.minContainerX = this.maxContainerX - this.numberOfRewards * (SPIN_ITEM_WIDTH + SPIN_ITEM_GAP);
      if (this.contentContainer) {
        this.spinItems.map((item) => {
          this.contentContainer.remove(item);
          item.destroy();
          this.contentContainer.destroy();
        });
      }
      if (this.table) {
        this.remove(this.table);
        this.table.destroy(true);
        this.table = null;
      }

      this.spinItems = [
        spinRewards.at(-1),
        ...spinRewards,
        ...spinRewards,
        ...spinRewards,
        ...spinRewards,
        ...spinRewards,
        ...spinRewards,
        ...spinRewards,
        ...spinRewards,
        ...spinRewards,
        spinRewards[0],
        spinRewards[1],
      ].map((item, index) => {
        const spinItem = new SpinItem(
          scene,
          SPIN_ITEM_WIDTH * (index + 1) + 40 * (index + 1),
          SPIN_CONTAINER_HEIGHT / 2 - 110,
          item
        );

        return spinItem;
      });
      this.contentContainer = scene.add
        .container()
        .add(this.spinItems)
        .setSize(SPIN_ITEM_WIDTH * this.numberOfRewards + SPIN_ITEM_GAP * (this.numberOfRewards - 1), 0);

      this.table = new ScrollablePanel(this.scene, {
        x: width / 2,
        y: height / 2 - 65,
        width: SPIN_CONTAINER_WIDTH,
        height: SPIN_CONTAINER_HEIGHT,
        scrollMode: 'x',
        background: this.scene.rexUI.add.roundRectangle({ radius: 10 }),
        panel: { child: this.contentContainer, mask: { padding: 1 } },
        slider: {},
        mouseWheelScroller: { focus: true, speed: 0.3 },
        space: { left: 40, right: 40, top: 40, bottom: 40, header: 10, footer: 10 },
      }).layout();

      this.table.setMouseWheelScrollerEnable(false);
      this.table.setScrollerEnable(false);
      this.contentContainer.x = this.maxContainerX;
      this.add(this.table);

      if (this.spinButton) {
        this.remove(this.spinButton);
        this.spinButton.destroy();
      }

      this.spinButton = new Button(
        scene,
        width / 2,
        height / 2 + this.popup.height / 2 - 20,
        'button-get-free-ueth',
        'button-get-free-ueth-pressed',
        () => {
          if (this.loading) return;
          this.showPopupConfirmSpin();
        },
        { sound: 'button-1', disabledImage: 'button-get-free-ueth-disabled' }
      );
      this.spinButton.setDisabledState(true);
      this.add(this.spinButton);

      if (this.arrowDown) {
        this.remove(this.arrowDown);
        this.arrowDown.destroy();
      }
      this.arrowDown = scene.add
        .image(width / 2, this.popup.y - this.popup.height / 2 + 170, 'arrow-spin-down')
        .setOrigin(0.5, 0.5);
      this.add(this.arrowDown);

      if (this.arrowUp) {
        this.remove(this.arrowUp);
        this.arrowUp.destroy();
      }
      this.arrowUp = scene.add
        .image(width / 2, this.arrowDown.y + SPIN_ITEM_HEIGHT, 'arrow-spin-up')
        .setOrigin(0.5, 0.5);
      this.add(this.arrowUp);

      if (this.confirmation) {
        this.remove(this.confirmation);
        this.confirmation.destroy();
      }

      if (this.loadingIcon) {
        this.remove(this.loadingIcon);
        this.loadingIcon.destroy();
      }

      if (this.loadingAnimation) {
        this.loadingAnimation.remove();
      }

      this.confirmation = scene.add
        .image(this.popup.x, this.popup.y, 'spin-confirmation')
        .setOrigin(0.5, 0.5)
        .setVisible(false);
      this.add(this.confirmation);

      this.loadingIcon = scene.add
        .image(this.confirmation.x - this.confirmation.width / 2 + 130, this.confirmation.y - 10, 'icon-loading-small')
        .setOrigin(0.5, 0.5)
        .setVisible(false);
      this.add(this.loadingIcon);

      this.loadingAnimation = scene.tweens.add({
        targets: this.loadingIcon,
        rotation: Math.PI * 2, // full circle
        duration: 3000,
        repeat: -1, // infinite
        ease: 'Cubic.out',
      });

      this.checkSpinButtonState();
    });

    scene.game.events.on('spin-airdrop-error', ({ code, message }) => {
      this.spinSound.stop();
      this.loading = false;
      this.popupTxnCompleted = new PopupTxnError({
        scene,
        code,
        description: message,
      });
      scene.add.existing(this.popupTxnCompleted);
      this.hidePopupConfirmSpin();
      this.close();
      this.checkSpinButtonState();
    });

    scene.game.events.on('spin-airdrop-result', ({ destinationIndex }) => {
      this.startSpinAnimation(destinationIndex);
    });

    scene.game.events.emit('request-spin-airdrop-rewards');
  }

  checkSpinButtonState() {
    let valid = true;

    if (this.loading) {
      valid = false;
    }

    this.spinButton?.setDisabledState(!valid);
  }

  showPopupConfirmSpin() {
    this.loading = true;
    this.spinButton?.setDisabledState(true);
    this.arrowDown?.setAlpha(ALPHA);
    this.arrowUp?.setAlpha(ALPHA);
    this.spinItems?.map((item) => item?.setAlpha(ALPHA));
    this.confirmation?.setVisible(true);
    this.loadingIcon?.setVisible(true);
    this.loadingAnimation?.resume();
    this.scene.game.events.emit('start-spin-airdrop');
  }

  hidePopupConfirmSpin() {
    this.arrowDown?.setAlpha(1);
    this.arrowUp?.setAlpha(1);
    this.spinItems?.map((item) => item?.setAlpha(1));
    this.confirmation?.setVisible(false);
    this.loadingIcon?.setVisible(false);
    this.loadingAnimation?.pause();
  }

  startSpinAnimation(destinationIndex) {
    this.hidePopupConfirmSpin();

    const randomDistanceFromCenter = randomNumberInRange(-SPIN_ITEM_WIDTH / 2 + 50, SPIN_ITEM_WIDTH / 2 - 50);
    const reward = this.spinRewards[destinationIndex];

    const destinationX =
      this.maxContainerX -
      destinationIndex * (SPIN_ITEM_WIDTH + SPIN_ITEM_GAP) -
      8 * this.numberOfRewards * (SPIN_ITEM_WIDTH + SPIN_ITEM_GAP) +
      randomDistanceFromCenter;

    this.scene.tweens.add({
      targets: this.contentContainer,
      x: [this.maxContainerX, destinationX],
      duration: SPIN_DURATION,
      // ease: 'Cubic.InOut',
      ease: 'Circ.Out',
      // easeParams: [SPIN_OUT, SPIN_IN],
      onStart: () => {
        this.startTime = Date.now();
        this.cPosition = 0;
      },
      onUpdate: () => {
        if (this.spinSound1.isPlaying) return;
        const x = (Date.now() - this.startTime) / 10000;
        const d = Math.sqrt(1 - Math.pow(x - 1, 2));

        let position = Math.floor((destinationX * d) / (SPIN_ITEM_WIDTH + SPIN_ITEM_GAP));
        if (position < this.cPosition) {
          this.cPosition = position;
          this.spinSound1.play();
        }
      },
      // completeDelay: 5000,
      onComplete: () => {
        this.loading = false;

        // this.close();
        // this.checkSpinButtonState();
        // this.contentContainer.x = this.maxContainerX;
        // this.scene.popupSpinReward?.showReward(reward);
        this.scene.tweens.add({
          targets: this.contentContainer,
          x: [destinationX, destinationX + 3],
          duration: 200,
          // ease: 'Cubic.InOut',
          ease: 'Sine.In',
          // easeParams: [SPIN_OUT, SPIN_IN],
          onStart: () => {},
          completeDelay: 2000,
          onComplete: () => {
            this.loading = false;

            this.close();
            this.checkSpinButtonState();
            this.contentContainer.x = this.maxContainerX;
            this.scene.popupSpinPlaytestReward?.showReward(reward);
          },
        });
      },
    });
  }

  resetSpinPosition() {
    this.contentContainer && (this.contentContainer.x = this.maxContainerX);
  }
}

export default PopupSpinPlaytest;
