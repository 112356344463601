import Popup from './Popup';
import TextButton from '../button/TextButton';
import { fontFamilies } from '../../../../utils/styles';
import configs from '../../configs/configs';

const { width, height } = configs;

class PopupUETHClaimed extends Popup {
  iconY = height / 2 - 200;
  iconX = width / 2;

  constructor(scene) {
    super(scene, 'popup-ueth-claimed', { title: 'Completed' });

    const buttonGreat = new TextButton(
      scene,
      width / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      this.close,
      'Great',
      { fontSize: '82px', sound: 'close' }
    );
    this.add(buttonGreat);

    this.glowFx = scene.add.image(this.iconX, this.iconY, 'glow-small').setOrigin(0.5, 0.5);
    this.add(this.glowFx);

    this.icon = scene.add.sprite(this.iconX, this.iconY + 120, '').setOrigin(0.5, 1);
    this.add(this.icon);

    this.checkIcon = scene.add.sprite(this.iconX + 120, this.iconY + 120, 'icon-check').setOrigin(0.5, 0.5);
    this.add(this.checkIcon);

    this.rewardText = scene.add
      .text(this.iconX, this.iconY + 255, '', {
        fontSize: '100px',
        fontFamily: fontFamilies.extraBold,
        color: '#30030B',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.rewardText);
  }

  showReward({ iconImg, value }) {
    this.icon.setTexture(iconImg);
    if (this.icon.width > 368) {
      this.icon.displayWidth = 368;
      this.icon.scaleY = this.icon.scaleX;
    }

    this.rewardText.text = `+ ${value}`;

    this.open();
  }
}

export default PopupUETHClaimed;
